<template>
  <div>
    <div v-if="isMobile === false">
      <div class="Message wrapper">
        <Header></Header>
        <div class="mainContent" v-if="languageSelect === 'zh'">
          <el-breadcrumb class="centerWidth" separator="/" replace>
            <el-breadcrumb-item :to="{ path: '/' }"
              >油气知识</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/Info' }"
              >个人中心
            </el-breadcrumb-item>
            <!-- <el-breadcrumb-item v-if="showItem" :to="{ path: '/secure' }"
            >账户安全</el-breadcrumb-item
            > -->
            <el-breadcrumb-item>{{ activeCrumb }}</el-breadcrumb-item>
          </el-breadcrumb>
          <el-container class="centerWidth">
            <el-aside width="218px" height="auto">
              <div class="ViewName">个人中心</div>
              <el-menu
                :default-active="activeMessageName"
                class="el-menu-vertical-demo"
              >
                <el-menu-item
                  v-for="(item, index) in messageNav"
                  :key="index"
                  :index="item.url"
                  @click.native="handleSelectMenu(item.url)"
                >
                  <span slot="title">
                    {{ item.name }}
                    <el-badge
                      v-if="item.url == '/receivedlikes' && unreadMessage"
                      class="mark"
                      :value="unreadMessage"
                      :max="99"
                    />
                  </span>
                </el-menu-item>
              </el-menu>
            </el-aside>
            <el-main>
              <router-view />
            </el-main>
          </el-container>
        </div>
        <div class="mainContent" v-if="languageSelect === 'en'">
          <el-breadcrumb class="centerWidth" separator="/" replace>
            <el-breadcrumb-item :to="{ path: '/' }"
              >Oil and Gas knowledge
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/Info' }"
              >Personal center
            </el-breadcrumb-item>
            <el-breadcrumb-item v-if="showItem" :to="{ path: '/secure' }"
              >Account security
            </el-breadcrumb-item>
            <el-breadcrumb-item>{{ activeCrumbEn }}</el-breadcrumb-item>
          </el-breadcrumb>
          <el-container class="centerWidth">
            <el-aside width="218px" height="auto">
              <div class="ViewName">Personal center</div>
              <el-menu
                :default-active="activeMessageName"
                class="el-menu-vertical-demo"
              >
                <el-menu-item
                  v-for="(item, index) in messageNav"
                  :key="index"
                  :index="item.url"
                  @click.native="handleSelectMenu(item.url)"
                >
                  <span slot="title">
                    {{ item.nameEn }}
                    <el-badge
                      v-if="item.url == '/receivedlikes' && unreadMessage"
                      class="mark"
                      :value="unreadMessage"
                      :max="99"
                    />
                  </span>
                </el-menu-item>
              </el-menu>
            </el-aside>
            <el-main>
              <router-view />
            </el-main>
          </el-container>
        </div>
        <Footer></Footer>
      </div>
    </div>
    <div v-if="isMobile === true">

      <template v-if="$route.path == '/user'">
        <div class="mainContent m-user-box">
          <div class="m-user-top-box">
            <p>{{ $t('user.user') }}</p>
            <img src="../assets/userTopBg.png"/>
          </div>
          <div class="m-user-info">
            <div class="m-user-info-left" @click="userInfo.userId ? toLink('/Info') : login()">
              <el-avatar
                :size="58"
                :src="userInfo.avatar ? baseURL + userInfo.avatar : avator"></el-avatar>
              <div v-if="userInfo.userId">
                <span>{{ userInfo.username }}</span>
                <p>{{ maskPhone(userInfo.phone) }}</p>
              </div>
              <div v-else>{{ $t('common.loginRegi') }}</div>
            </div>
            <i class="el-icon-arrow-right"></i>
          </div>

          <div class="m-cell-box">
            <div class="m-cell-item" v-for="(item, index) in messageNav.slice(1)" :key="index" @click="userInfo.userId ? toLink(item.url) : login()">
              <div class="m-cell-left">
                <van-icon :name="item.icon" size="20" />
                <span>{{ item[trans('name,nameEn')] }}</span>
              </div>
              <div class="m-cell-right"><i class="el-icon-arrow-right"></i></div>
            </div>
          </div>
          
          <p v-if="userInfo.userId" class="loginOut" @click="outLogin">{{ $t('user.logout') }}</p>
        </div>
        <BottomBanner v-if="isMobile === true"></BottomBanner>
      </template>

      <router-view v-else />
    </div>

    <!-- <Footer v-if="isMobile === false"></Footer> -->
    <LoginTip
      :dialogVisible="dialogVisible"
      @hideDialog="hideDialog"
    ></LoginTip>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BottomBanner from "@/components/BottomBanner.vue";
import LoginTip from "@/components/LoginTip.vue";
import { getAuthorize, logOut } from "@/api/user";
import { Icon } from 'vant';
import mixins from "@/utils/mixins.js";

export default {
  name: "User",
  mixins: [mixins],
  components: { LoginTip, BottomBanner, Header, Footer, [Icon.name]: Icon },
  data() {
    return {
      dialogVisible: false,
      userId: localStorage.getItem("userId"),
      baseURL: this.$utils.baseURL,
      userInfo: this.$store.state.user,
      avator: require("@/assets/avator.png"),
      sliptrue: false,
      isMobile: false,
      languageSelect: "",
      activeCrumb: "个人信息",
      activeCrumbEn: "Personal information",
      activeMessageName: "/Info",
      messageNav: [
        {
          url: "/Info",
          name: "个人信息",
          nameEn: "Personal information",
        },
        {
          icon: 'like-o',
          url: "/collection",
          name: "我的收藏",
          nameEn: "My collection",
        },
        {
          icon: 'bill-o',
          url: "/orderList",
          name: "我的订单",
          nameEn: "My order",
        },
        {
          icon: 'chat-o',
          url: "/message",
          name: "系统消息",
          nameEn: "Information management",
        },
        {
          icon: 'shield-o',
          url: "/secure",
          name: "账户安全",
          nameEn: "Subject subscription",
        },
        // {
        //   url: "/logout",
        //   name: "退出登录",
        //   nameEn: "login out",
        // },
      ],
      showItem: false,
    };
  },
  computed: {},
  created() {
    localStorage.setItem("myclick", 1);
    console.log("userId", typeof this.userInfo);
    console.log("userId", this.isObjectEmpty(this.userInfo));
    console.log("userId", Object.keys(this.userInfo).length === 0);
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    this.activeMessageName = this.$route.path;
    let that = this;
    that.messageNav.forEach(function (item) {
      if (item.url == that.activeMessageName) {
        that.activeCrumb = item.name;
        that.activeCrumbEn = item.nameEn;
      }
    });
    if (
      that.$route.path === "/changePhone" ||
      that.$route.path === "/changePassword" ||
      that.$route.path === "/changeEmail"
    ) {
      that.showItem = true;
      that.activeMessageName = "/secure";
      let type = this.$route.query.type;
      let path = this.$route.path;
      if (path == "/changePhone") {
        that.activeCrumb = type == 1 ? "修改手机号" : "绑定手机号";
        that.activeCrumbEn =
          type == 1 ? "Modify mobile phone number" : "Bind mobile phone number";
      }
      if (path == "/changePassword") {
        that.activeCrumb = "修改密码";
        that.activeCrumbEn = "Change password";
      }
      if (path == "/changeEmail") {
        that.activeCrumb = type == 1 ? "修改邮箱" : "绑定邮箱";
        that.activeCrumbEn = type == 1 ? "Modify email" : "Bind email";
      }
    } else {
      that.showItem = false;
    }
  },
  watch: {
    $route() {
      this.activeMessageName = this.$route.path;
      let that = this;
      that.messageNav.forEach(function (item) {
        if (item.url === that.activeMessageName) {
          that.activeCrumb = item.name;
          that.activeCrumbEn = item.nameEn;
        }
      });
      if (
        that.$route.path === "/changePhone" ||
        that.$route.path === "/changePassword" ||
        that.$route.path === "/changeEmail"
      ) {
        that.showItem = true;
        that.activeMessageName = "/secure";
        let type = that.$route.query.type;
        let path = that.$route.path;
        if (path == "/changePhone") {
          that.activeCrumb = type == 1 ? "修改手机号" : "绑定手机号";
          that.activeCrumbEn =
            type == 1
              ? "Modify mobile phone number"
              : "Bind mobile phone number";
        }
        if (path == "/changePassword") {
          that.activeCrumb = "修改密码";
          that.activeCrumbEn = "Change password";
        }
        if (path == "/changeEmail") {
          that.activeCrumb = type == 1 ? "修改邮箱" : "绑定邮箱";
          that.activeCrumbEn = type == 1 ? "Modify email" : "Bind email";
        }
      } else {
        that.showItem = false;
      }
    },
    "$store.state.user"(newValue) {
      this.userInfo = newValue;
    },
  },
  methods: {
    maskPhone(num){
      return num.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
    },
    toLink(path){
      this.$router.push({
        path
      })
    },
    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    login() {
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        window.location.replace(res.data.url);
      });
    },
    slip() {
      if (this.sliptrue === true) {
        this.sliptrue = false;
      } else {
        this.sliptrue = true;
      }
    },
    handleSelectMenu(url) {
      console.log("url1" + url);
      if (url !== this.$route.path) {
        console.log("url2" + url);
        if (url === "/logout") {
          // 如果点击的是退出登录项
          this.handleLogout(); // 执行退出登录操作
        } else {
          // 如果点击的不是退出登录项，则进行路由切换
          this.$router.push({ path: url });
        }
      }
    },
    handleLogout() {
      // 在这里执行退出登录的逻辑
      console.log("执行退出登录逻辑");
      // 例如：调用您之前提供的 outLogin 方法
      this.outLogin();
    },
    outLogin() {
      console.log("调用退出登录逻辑");
      // location.reload();
      logOut()
        .then((res) => {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("access_token");
          localStorage.removeItem("userId");
          localStorage.removeItem("typeInfo");
          localStorage.removeItem("access_tokenout");
          localStorage.removeItem("Zyaccess_token");
          localStorage.removeItem("idass");
          this.$store.state.access_token = "";
          this.$store.state.idass = "";
          this.$store.state.user = {};
          // deleteUserAgent(localStorage.getItem("murmur"))
          let data = {
            userAgent: localStorage.getItem("murmur"),
          };
          this.$axios
            .post(
              "/auth/oauth/deleteUserAgent",
              data
            )
            .then(() => {
              localStorage.removeItem("murmur");
              window.location.href = res.data;
            });
        })
        .catch((error) => {
          console.error("退出登录失败:", error);
          this.$message.error("退出登录失败，请重试"); // 提示用户退出登录失败
          // 可以在此处执行其他的错误处理逻辑，例如重新加载页面或者显示其他提示信息
        });
    },
  },
  mounted() {
    // Check if the device width is less than 768px (example for mobile)
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
};
</script>
<style scoped lang="scss">
.itemLogo {
  width: 0;
  height: 0;
  margin-left: 40vw;
  color: #6e6e6eff;
  font-size: 25px;
}

.itemLogo1 {
  position: absolute;
  width: 0;
  height: 0;
  top: -5px;
  left: 75vw;
  color: #6e6e6eff;
  font-size: 25px;
}

.el-aside {
  background: url(../assets/userBg.png) no-repeat right bottom, #fff;
  background-size: 172px 164px;
  padding-bottom: 50px;
  text-align: center;

  .ViewName {
    height: 64px;
    line-height: 64px;
    color: #333333;
    font-size: 20px;
    margin: 0 16px;
    border-bottom: 1px solid #ebebeb;
  }

  .el-menu {
    background: none !important;
    font-size: 16px;
    color: #333333;
    border: none !important;
    // margin-top: 19px;
    // padding: 0 15px;
    text-align: center;
    overflow: hidden;

    .el-menu-item,
    .el-submenu__title {
      font-size: 16px;
      // border-bottom: rgba(128, 128, 128, 0.1) 1px solid;
      height: 64px;
      line-height: 64px;

      span {
        position: relative;

        .el-badge {
          position: absolute;
          left: calc(100% + 12px);
        }
      }
    }

    .el-menu-item.is-active {
      background: rgba(26, 121, 255, 0.1);
      color: var(--main-color);
      position: relative;
    }

    .el-menu-item.is-active::before {
      content: " ";
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      position: absolute;
      background: var(--main-color);
    }

    .el-menu-item:focus,
    .el-menu-item:hover {
      background: rgba(26, 121, 255, 0.1);
      color: var(--main-color);
      font-weight: 500;
    }
  }
}

.el-main {
  padding: 0;
  margin-left: 10px;
}

.mainContent {
  background: #f8f8f8;
  overflow: hidden;
  margin-bottom: 0;
  padding-bottom: 50px;
}

.loginOut {
  position: absolute;
  top: 485px;
  left: 0;
  margin: 24px 34px;
  width: 80%; 
  height: 44px;
  line-height: 44px;
  border-radius: 22px;
  background: var(--main-bg-color);
  color: #fff;
  text-align: center;
  font-size: 17px;
}
.m-user-box{
  height: calc(100vh - 50px);
  .m-user-top-box{
    position: relative;
    & > p{
      position: absolute;
      top: 40px;
      left: 20px;
      font-size: 28px;
      color: white;
      font-weight: bolder;
      line-height: 30px;
    }
    img{
      height: 160px;
      vertical-align: top;
      object-fit: fill;
    }
  }
  .m-user-info{
    position: relative;
    margin: -70px 20px 0;
    background-color: #fff;
    display: flex;
    overflow: hidden;
    border-radius: 8px;
    padding: 20px;
    align-items: center;
    .m-user-info-left{
      flex: 1;
      display: flex;
      align-items: center;
      & > span{
        margin-right: 10px;
      }
      .el-avatar {
        background-color: transparent;
      }
      & > div{
        span{
          display: block;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 6px;
        }
        p{
          font-size: 12px;
          color: #999;
        }
      }
    }
    &:active{
      background-color: #f5f5f5;
    }
  }
  .m-cell-box{
    margin: 20px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    .m-cell-item{
      padding: 14px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & + .m-cell-item{
        border-top: 1px solid #f1f1f1;
      }
      .m-cell-left{
        & > i{
          margin-right: 10px;
        }
      }
      &:active{
        background-color: #f5f5f5;
      }
    }
  }
}
</style>

